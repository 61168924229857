import KnTable from '../../../shared/components/KnTable.vue';
import { mapMutations, mapState, mapGetters } from 'vuex';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import {
  fetchGroups,
  fetchStudents,
} from '../../../employees/helpers/reportCardOptions';
import { getFullName } from '../../../shared/helpers/dataUtils';
import {
  fetchStudentByName,
  findGroupAssignedToStudent,
} from '../../helpers/utilsStudent';
import { canAdd, canChange } from '../../../shared/helpers/permissionsUtils';
export default {
  name: 'EarlyStimulationIndex',
  components: { KnTable },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      avatares: ['ardilla.png', 'castor.png', 'conejo.png', 'mapache.png'],
      headers: [
        { text: '', value: 'avatar' },
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        { text: 'Grupo', value: 'grupo', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      items: [],
      inactiveItems: [],
      students: [],
      groups: [],
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
    ...mapGetters([
      'isTutor',
      'tutorId',
      'hasAdminPermission',
      'isTeacherEmploye',
      'employeeId',
    ]),
  },
  watch: {
    search: {
      async handler() {
        await this.getPaginatedStudents(1);
      },
    },
    showInactive: {
      async handler() {
        await this.getPaginatedStudents(1);
      },
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.fetchData();
    await this.getPaginatedStudents(1);
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    canAdd: canAdd,
    canChange: canChange,
    async fetchData() {
      this.loading = true;
      const { ok: okGroups, data: dataGroups } = await fetchGroups({
        institutionId: this.institutionId,
        schoolCycleId: null,
        teacherId: this.isTeacherEmploye ? this.employeeId : null,
        systemStatus: true,
        limit: 100,
      });

      this.groups = okGroups ? dataGroups : [];
      this.loading = false;
    },
    async getPaginatedStudents(page = 1) {
      this.setPaginationPage(page);
      if (!this.loading) {
        this.items = [];
        this.loading = true;
        // console.log('Valor de search', this.search);
        if (this.search !== null && this.search !== '') {
          // Se debe llamar a la ruta que busca por nombre personal
          const { ok, data, message } = await fetchStudentByName(
            this.search,
            this.pagination,
            !this.showInactive,
            this.institutionId
          );
          // console.log('Response busqueda alumnos', data);
          if (ok) {
            const studentsWithoutGroups = data.results.filter(
              (student) => student.is_estimulacion_temprana === true
            );
            this.buildItem(studentsWithoutGroups);
            this.setPaginationCount(studentsWithoutGroups.length);
          } else {
            console.log(
              'No se pudieron obtener los alumnos por nombre',
              message
            );
          }
          // console.log('Se debe llamar a la ruta de busqueda por nombre');
        } else {
          // console.log('Se debe mandar a traer a los alumnos');
          const { ok, data, message, count } = await fetchStudents({
            institutionId: this.institutionId,
            is_estimulacion_temprana: true,
            tutorId: this.isTutor ? this.tutorId : null,
            systemStatus: !this.showInactive,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });

          if (ok) {
            this.setPaginationCount(count);
            const studentsWithoutGroups = data;
            this.buildItem(studentsWithoutGroups);
          } else {
            console.error(
              'Error al obtener alumnos de estimulacion temprana' + message
            );
          }
        }
        this.loading = false;
      }
    },
    buildItem(studentsWithoutGroups) {
      for (const student of studentsWithoutGroups) {
        const group = findGroupAssignedToStudent(student.id, this.groups, true);
        // console.log('findGroupAssignedToStudent', group);
        let item = {
          ...student,
          avatar: group
            ? group.imagen_grupo
              ? group.imagen_grupo.imagen
              : 'huellita.png'
            : 'huellita.png',
          nombre: getFullName(student.datos_personales),
          grupo: group ? group.nombre_grupo : 'Sin asignar',
        };
        this.items.push(item);
      }
    },
  },
};
